import React, { Component } from "react";
import {
  Container,
  Card,
  List,
  Button,
  Popup,
  Image,
  Grid,
  Divider,
} from "semantic-ui-react";
import LayoutInner from "../../components/layout/layout-inner";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import SecondaryHeading from "../../components/secondary-heading/secondary-heading";
import MainHeading from "../../components/main-heading/main-heading";
import DealCard from "../../components/deal-card/deal-card";
import SEO from "../../components/seo/seo";

import "./index.css";

class Instruments extends Component {
  componentDidMount() {
    // Scroll to the top
    window.scrollTo(0, 0);
  }

  copyToClipboard = (btnClass) => {
    // eslint-disable-next-line no-undef
    const data = [ new ClipboardItem({ "text/plain": new Blob(["NRRS20"], { type: "text/plain" }) }) ];
    navigator.clipboard.write(data).then(() => {
      const copyBtn = document.querySelector(`.${btnClass}`);
      const initialCopyBtnText = copyBtn.innerHTML;
      copyBtn.innerText = "Copied!";
      copyBtn.blur();
      setTimeout(() => {
        copyBtn.innerHTML = initialCopyBtnText;
      }, 2e3);
    });
  };

  pageCopy = () => {
    return(
      <div className="instrument-deals">

      {/* Heading */}
      <MainHeading
        heading="Rock Star Ready Equipment"
        subHeading="Exclusive Offers and Bundle Deals"
      />

      {/* Divider */}
      <div style={{ height: "10px", backgroundColor: "#0072ce" }} />
      <div style={{ height: "5px", backgroundColor: "#00e7ef" }} />

      {/* Description */}
      <h2>
        Looking to hire or buy an instrument so your child can practice at home?
        We can help
      </h2>
      <p>
        In-school we bring everything your child needs for their band lessons,
        and you can join in Rocksteady lessons online with or without an
        instrument. However, if you do want an instrument for your child to
        practise and play at home, but don’t want to spend hours researching
        what to buy or hire, we’ve teamed up with online music retailer Normans to bring
        you some exclusive instrument packages for current Rocksteady
        subscribers.
      </p>
      <p>Rocksteady is not affiliated with Normans.</p>

      <Image src="/images/instruments/band-instrument-deals.jpg" />

      {/* Heading for Buying an Instrument */}
      <SecondaryHeading heading="What's available to buy?" />

      {/* Instrument cards for buying instruments */}
      <Card.Group stackable itemsPerRow={2}>
        <DealCard
          img="/images/instruments/buy-drums.jpg"
          heading="Drums Starter Pack | £204.99"
          description="The Carlsbro CSD100 Electronic Drum Kit is an ideal electronic drum kit for beginners."
          buttonPath="https://rocksteady-music-school.class4kids.co.uk/info/210"
          buttonText="View Deal"
        />

        <DealCard
          img="/images/instruments/buy-guitar.jpg"
          heading="3/4 Guitar Starter Pack | £109.99"
          description="The Rocket ¾ Electric Guitar Starter Pack is a great option as a first guitar for younger learners. It is a 3/4 size instrument, so suitable for most 4-11 years olds. This pack has everything you need to get started at home – guitar, amplifier, leads, plectrums, strap and more."
          buttonPath="https://rocksteady-music-school.class4kids.co.uk/info/209"
          buttonText="View Deal"
        />

        <DealCard
          img="/images/instruments/buy-bass.jpg"
          heading="3/4 Bass Starter Pack | £208.99"
          description="The Stagg 3/4 size B300 Guitar is especially designed for children. It has a slightly shorter neck length, so suitable for most young learners aged between 4-11 years, who may not quite have the full reach of a full-size bass. This pack has everything you need to get started at home – guitar, amplifier, leads, plectrums. strap and more."
          buttonPath="https://rocksteady-music-school.class4kids.co.uk/info/208"
          buttonText="View Deal"
        />

        <DealCard
          img="/images/instruments/buy-keyboard.jpg"
          heading="Keyboard Starter Pack | £128.99"
          description="The AXP2 Electronic Keyboard by Axus features 61 touch sensitive keys, with a “box style” design to help provide a much more realistic piano style experience. This pack has everything you need to get started at home – keyboard, stand, stool and headphones."
          buttonPath="https://rocksteady-music-school.class4kids.co.uk/info/211"
          buttonText="View Deal"
        />
      </Card.Group>

      <DealCard
        img="/images/instruments/buy-vocals.jpg"
        heading="Vocals Starter Pack | £112.99"
        description="The Stagg Vocals pack is ideal for home use. It has Bluetooth connectivity for backing tracks and a rechargeable 12v battery. Controls on the back allow you to adjust the microphone volume, AUX volume and turn Bluetooth connection on or off. This pack has everything you need to get started at home – PA speaker, microphone, mic stand and aux in cable for phone and tablet with a variety of inputs."
        buttonPath="https://rocksteady-music-school.class4kids.co.uk/info/212"
        buttonText="View Deal"
        fullwidth
      />
      <br />

      <Divider />

      <h3 className="how-to-buy">How to buy</h3>
      <p>
        To order click view deal to visit our Class4Kids site and follow the
        instructions. Normans will be in touch within one working day and your
        instruments will usually be delivered in a few days.
      </p>

      {/* Heading for Renting an Instrument */}
      <SecondaryHeading heading="What's available to hire?" />

      {/* Description for Renting an Instrument */}
      <p>
        Not ready to splash out on your own kit? Don’t worry there are options
        to hire for a small monthly fee. (Minimum hire period is three months).
      </p>

      <Grid stackable className="info-hiring">
        <Grid.Row columns={2}>
          <Grid.Column>
            <h3>What happens after three months?</h3>
            You can choose to:
            <List bulleted>
              <List.Item>Give the instrument back.</List.Item>
              <List.Item>
                Carry on paying the monthly hire price for the remaining nine
                months. After hiring for 12 months in total the instrument will
                be yours forever.
              </List.Item>
              <List.Item>
                Or pay a settlement price and own the instrument straight away.
                (Cheaper than hiring for 12 months)
              </List.Item>
            </List>
          </Grid.Column>

          <Grid.Column>
            <h3>How to hire</h3>
            <List bulleted>
              <List.Item>
                Complete the form on Normans Musical Instruments hire page.
              </List.Item>
              <List.Item>
                Enter this teacher code{" "}
                <Popup
                  trigger={<strong className="rs-dark-blue">NRRS20</strong>}
                  hoverable
                  className="copy-popup"
                >
                  <Button
                    className="normans-code-inline"
                    onClick={() => this.copyToClipboard("normans-code-inline")}
                  >
                    Copy code
                  </Button>
                </Popup>
              </List.Item>

              <List.Item>Fill out the direct debit form.</List.Item>
              <List.Item>Normans will confirm your request.</List.Item>
              <List.Item>
                Your instrument will be sent out as soon as possible.
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <p>Currently you can choose to hire:</p>
      {/* Instrument cards for hiring instruments */}

      <Card.Group stackable itemsPerRow={2}>
        <DealCard
          img="/images/instruments/Rent-Normans-Guitar.jpg"
          heading="¾ size or full-size electric guitar bundle"
          description="– includes guitar, amplifier, gig bag, spare strings, capo, guitar strap, digital tuner, plectrums and cables."
          buttonPath="https://www.normans.co.uk/topic/rent-an-electric-guitar"
          buttonText="Hire Now"
          copyButton={this.copyToClipboard}
          instrument="guitar"
        />

        <DealCard
          img="/images/instruments/Rent-Normans-Keyboard.jpg"
          heading="Digital keyboard bundle"
          description="– includes keyboards, music stand, mains adaptor, keyboard stand, bench and headphones."
          buttonPath="https://www.normans.co.uk/topic/rent-a-keyboard"
          buttonText="Hire Now"
          copyButton={this.copyToClipboard}
          instrument="keyboard"
        />
      </Card.Group>
      <br />
      <br />
      <br />
    </div>
    )
  }

  pageContent = () => (
    <LayoutInner menuEnabled={false}>
      <SEO title="Instruments" keywords={[`rocksteady`]} indexing="noindex" />
      <Container>
        <SecondaryNavBar
          product="generic"
          title="Instrument Deals and Rentals"
          titlePath="/instruments/"
          titleActive={true}
          links={[]}
        />
        {this.pageCopy()}
      </Container>
    </LayoutInner>
  );

  render() {
    return this.pageContent();
  }
}

export default Instruments;

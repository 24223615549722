import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import "./main-heading.css";


const MainHeading = ({
  heading,
  subHeading,
  description,
}) => {
  const headingColumn = () => {
    return (
      <Grid.Column width={16}>
        <Header as="h1">
          {heading}
          {subHeading ? (
            <Header.Subheader>{subHeading}</Header.Subheader>
          ) : null}
        </Header>
        {description ? (
          <p className="main-heading-description">{description}</p>
        ) : null}
      </Grid.Column>
    );
  };

  return (
    <Grid stackable>
      <Grid.Row className="main-heading">
        {headingColumn()}
      </Grid.Row>
    </Grid>
  );
};

MainHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string,
};

MainHeading.defaultProps = {
  subHeading: null,
  description: null,
};

export default MainHeading;
